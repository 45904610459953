<template>
  <div>
    <title-nav :title="'Push'" :nav="'CS / Push'" />
    <b-container fluid>
      <div class="card">
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <!-- 회원번호 -->
            <template v-slot:cell(user_name)="data">
              <router-link
                :to="{ name: 'detail', params: { usersn: data.item.user_sn } }"
                variant="link"
                >{{ data.value }}</router-link
              >
            </template>
            <template v-slot:cell(reg_dt)="data">
              {{ data.value | dateFilter }}
            </template>
            <template v-slot:cell(notice_sn)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(push_type)="data">
              {{ getCodeName(data.value) }}
            </template>
            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="page"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { getPushList } from '@/api/cs'
import { getCode } from '@/api/code'

export default {
  name: 'Push',
  data() {
    return {
      page: 1,
      records: 0,
      rowPerPage: 20,
      lastPage: 1,
      isBusy: false,
      noti_type: '',
      noti_title: '',
      noti_code: null,
      noti_sn: '',
      options: [],
      isEditMode: false,
      push_date: '',
      push_time: '',
      fields: [
        {
          key: 'push_sn',
          label: 'No.',
          colType: 'span'
        },
        {
          key: 'reg_dt',
          label: '발송일시',
          colType: 'span'
        },
        {
          key: 'push_type',
          label: '푸시 타입',
          colType: 'span'
        },
        {
          key: 'title',
          label: '제목',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'user_name',
          label: '대상',
          colType: 'span'
        }
      ],
      items: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      contents: '',
      qna_sn: '',
      itemsDetail: '',
      answer: '',
      code: null
    }
  },
  methods: {
    getCodeName(code) {
      const name = this.code && this.code.find(value => value.cd_value === code).cd_name_kr
      // console.log(`name: ${name}`)
      return name
    },
    async getList() {
      this.isBusy = true
      const response = await getPushList(
        this.page,
        this.rowPerPage,
        this.$route.query.notice_sn,
        this.$route.query.user_sn
      )

      this.items = response.data.list
      this.lastPage =
        Math.floor(response.data.totCnt / this.rowPerPage) +
        (response.data.totCnt % this.rowPerPage === 0 ? 0 : 1)
      this.isBusy = false
    },
    setPage(page) {
      this.page = this.page + page
      this.getList()
    },
    setCondition() {
      this.page = 1
      this.getList()
    },
    async getCodeList() {
      this.code = await getCode('131')
    }
  },
  mounted() {
    this.getCodeList()
    this.getList()
  }
}
</script>

<style scoped>
table tbody tr .td-title {
  color: #fff;
  background-color: #212529;
  border-color: #383f45;
  padding-left: 10px;
}

table tbody tr .td-data {
  color: #000;
  background-color: #ffffff;
  border-color: #383f45;
  text-align: left;
  padding-left: 10px;
}
</style>
